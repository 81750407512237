import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import * as style from "../styles/about.module.scss"

const About = () => {
  return (
    <Layout>
      <Seo
        title="About | 株式会社アローズ"
        description="私たちができること向かっていく未来。消費者が「欲しい」「買いたい」と思う製品をゼロから開発、消費者が求めるモノがあれば世界中から探しだす。"
      />
      <div id="about">
        <div className={style.mv}>
          <div className={style.inner}>
            <h1>ABOUT</h1>
            <h2 className={style.catch}>アローズについて</h2>
          </div>
        </div>
      </div>

      <div className={style.message}>
        <p>TOP MESSAGE</p>
        <h2>
          ビジネスと社会貢献への
          <br className={style.hiddenPC} />
          課題解決
        </h2>
        <div className={style.txt}>
          「商品開発力」。これこそが、四半世紀にわたり世の中から必要とされてきた当社の最大の強みです。
          <br />
          消費者が求める「WANTS」は常に変化し、
          <br />
          世の中に存在する「PRODUCTS」との間には常に「GAP」が生じます。
          <br />
          我々は、ユニークな「発想力」と、スピードある「実現力」で、
          <br />
          この「GAP」を埋める「新たな商品」を世に送り出し続けています。
          <br />
          <br />
          代表取締役　矢吹 好永
        </div>
      </div>

      <section>
        <div className={style.title}>
          <h3>会社概要</h3>
        </div>
        <div className={style.inner}>
          <dl className={style.property}>
            <dt>会社名</dt>
            <dd>株式会社アローズ （英文 Arrows & Company Limited）</dd>
          </dl>
          <dl className={style.property}>
            <dt>設立</dt>
            <dd>1997年7月22日</dd>
          </dl>
          <dl className={style.property}>
            <dt>代表者</dt>
            <dd>代表取締役 矢吹 好永</dd>
          </dl>
          <dl className={style.property}>
            <dt>所在地</dt>
            <dd>東京都渋谷区笹塚1-62-3 アルス笹塚ビル1Ｆ</dd>
          </dl>
          <dl className={style.property}>
            <dt>電話番号</dt>
            <dd>03-5365-3838</dd>
          </dl>
          <dl className={style.property}>
            <dt>FAX番号</dt>
            <dd>03-5365-3880</dd>
          </dl>
          <dl className={style.property}>
            <dt>取引銀⾏</dt>
            <dd>
              みずほ銀行 新宿西口支店／ 三井住友銀行 笹塚支店／八千代銀行
              笹塚支店
            </dd>
          </dl>
          <dl className={style.property}>
            <dt>主要取引先</dt>
            <dd>
              株式会社ジェイオーディ、株式会社日本文化センター、株式会社ベルーナ、アマゾンドットコムインターナショナル、株式会社ＱＶＣジャパン、株式会社ジャパネットたかた、株式会社マクアケ、株式会社CAMPFIRE、他多数
              <br />
              <br />
              順不同
            </dd>
          </dl>
        </div>
      </section>

      <section>
        <div className={style.title}>
          <h3>沿革</h3>
        </div>
        <div className={style.inner}>
          <dl className={style.history}>
            <dt>1997年7月</dt>
            <dd>
              株式会社アローズ設立
              <br />
              アパレル製造・輸入・卸販売開始
            </dd>
            <dt>2001年5月</dt>
            <dd>ネット販売会社エーディーシーを設立し、ネット直販事業開始</dd>
            <dt>2002年10月</dt>
            <dd>
              カタログ通販ベルーナ取引開始
              <br />
              「銀座・丸の内ＯＬ100人が選ぶワイシャツ」シリーズがヒット
            </dd>
            <dt>2003年11月</dt>
            <dd>
              ＴＶ通販ＱＶＣジャパン取引開始
              <br />
              高級真珠ジュエリー「IRIS・SPIRA」シリーズＴＶ取扱開始
            </dd>
            <dt>2005年3月</dt>
            <dd>ＴＶ通販ジャパネットたかた取引開始</dd>
            <dt>2010年5月</dt>
            <dd>スマートフォンケース取扱開始</dd>
            <dt>2011年1月</dt>
            <dd>インターネット通販Amazon取引開始</dd>
            <dt>2012年4月</dt>
            <dd>ＱＶＣジャパン「Long Partnership Award」授与</dd>
            <dt>2012年9月</dt>
            <dd>スマホケース専門店「キラキラアイランドAmazon店」開設</dd>
            <dt>2015年9月</dt>
            <dd>家電メーカー丸隆がグループ会社となり、家電製品の取扱開始</dd>
            <dt>2019年12月</dt>
            <dd>自動翻訳機「ポケトーク」取扱開始</dd>
            <dt>2020年3月</dt>
            <dd>
              スマートフォンケース「stars.arrows」インスタ公式アカウント開設
            </dd>
            <dt>2020年5月</dt>
            <dd>除菌製品・マスク取扱開始</dd>
            <dt className={style.last}>2020年12月</dt>
            <dd>空間除菌「KIWAMIST」がヒット</dd>
          </dl>
        </div>
      </section>

      <section>
        <div className={style.title}>
          <h3>取引先</h3>
        </div>
        <div className={style.inner}>
          <ul className={style.suppliers}>
            <li>・株式会社ジェイオーディ</li>
            <li>・株式会社日本文化センター</li>
            <li>・株式会社ベルーナ</li>
            <li>・アマゾンドットコムインターナショナル</li>
            <li>・株式会社ＱＶＣジャパン</li>
            <li>・株式会社ジャパネットたかた</li>
            <li>・株式会社マクアケ</li>
            <li>・株式会社CAMPFIRE</li>
          </ul>
          <p>他多数 （順不同）</p>
        </div>
      </section>

      <div className={`footNav`}>
        <Link to="/mind" className={`mind`}>
          <p className={`mtM`}>MIND</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/service" className={`service`}>
          <p className={`mtM`}>SERVICE</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/recruit" className={`recruit`}>
          <p className={`mtM`}>RECRUIT</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
      </div>
    </Layout>
  )
}

export default About
